/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Archive,
  Briefcase,
  Database,
  FileText,
  Folder,
  Home,
  Monitor,
  Users,
  Map,
  Edit,
  CheckSquare,
  Droplet,
  User,
  Tool,
  Clipboard,
  HelpCircle,
} from "react-feather";

import AuthGuard from "../components/AuthGuard";
import DeveloperGuard from "../components/DeveloperGuard";
import DeveloperVisibilityFilter from "../components/DeveloperVisibilityFilter";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";

import Blank from "../pages/pages/Blank";
import Landing from "../pages/presentation/Landing";
import * as inflector from "inflected";
import Default from "../pages/dashboards/Default";
import { CrudProvider } from "../CrudProvider";

import PublicMap from "../pages/publicMap";
import Production from "../pages/dashboards/data entry/Production";
import UserVisibilityFilter from "../components/UserVisibilityFilter";
import PDI from "../pages/dataAccess/reports/PDI";
import UiReportAllPermitsReport from "../pages/dataAccess/reports/UiReportAllPermitsReport";
import NewWaterQuality from "../pages/dashboards/data entry/NewWaterQuality";
import CurrentExemptWellUseSummaryReport from "../pages/dataAccess/reports/CurrentExemptWellUseSummaryReport";
import Rolodex from "../pages/dashboards/data entry/Rolodex";
import NewWaterLevels from "../pages/dashboards/data entry/NewWaterLevels";
import PermitsToWells from "../pages/dataAccess/associations";
import WellOwnerSearchReport from "../pages/dataAccess/reports/WellOwnerSearchReport";
import WaterQualityPDFReport from "../pages/dataAccess/reports/WaterQualityPDFReport";
import AllWellsReport from "../pages/dataAccess/reports/AllWellsReport";
import WaterLevelsReport from "../pages/dataAccess/reports/WaterLevelsReport";
import DrawdownTool from "../pages/dataAccess/reports/DrawdownTool";
import CallMerge from "@material-ui/icons/CallMerge";
import UsersHome from "../pages/users/UsersHome";
import LoginsToPermits from "../pages/dataAccess/associations/LoginsToPermits";
import UserGuides from "../pages/dataAccess/reports/UserGuides";
import WellsToOwners from "../pages/dataAccess/associations/WellsToOwners";
import WellsToContacts from "../pages/dataAccess/associations/WellsToContacts";
import DownloadTest from "../pages/developer/DownloadTest";
import { Code, TrackChanges } from "@material-ui/icons";
import SignalFireTelemetry from "../pages/dataAccess/reports/signalFireTelemetry/SignalFireTelemetry";
import WaterQualityRecordsSearch from "../pages/dataAccess/tools/waterQualityRecordsSearch";
import WaterQualityRecordsSearchResults from "../pages/dataAccess/tools/waterQualityRecordsSearch/ResultsPage";
import NextYearSetup from "../pages/dataAccess/nextYearSetup";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const CrudIndexPage = async(() => import("../components/crud/CrudIndexPage"));
const CrudViewPage = async(() => import("../components/crud/CrudViewPage"));

const getSidebarMenuItem = (item) => {
  const slug = inflector.dasherize(inflector.underscore(item.name));
  return {
    layoutOverride: item.layoutOverride ?? null,
    id: item.sidebarName ?? inflector.titleize(item.name),
    icon: item.icon || <Database />,
    path: `/models/${slug}`,
    model: inflector.singularize(item.name),
    name: item.sidebarName,
    component: CrudIndexPage,
    config: require(`../pages/models/${item.name}Config`),
    provider: CrudProvider,
    children: item.children,
    header: item.header,
    guard: item.guard,
    visibilityFilter: item.visibilityFilter,
  };
};

const getCrudRoute = (route) => {
  const config = require(`../pages/models/${route.name}Config`);
  const slug = inflector.dasherize(inflector.underscore(route.name));

  return {
    id: inflector.titleize(route.name),
    path: `/models/${slug}`,
    model: inflector.singularize(route.name),
    component: CrudIndexPage,
    provider: CrudProvider,
    config,
    crud: [
      {
        path: `/models/${slug}/:id`,
        name: `View ${inflector.titleize(inflector.singularize(route.name))}`,
        component: CrudViewPage,
        provider: CrudProvider,
        model: inflector.singularize(route.name),
        config,
      },
      {
        path: `/models/${slug}/add`,
        name: `Add ${inflector.titleize(inflector.singularize(route.name))}`,
        component: CrudViewPage,
        provider: CrudProvider,
        model: inflector.singularize(route.name),
        config,
      },
    ],
  };
};

const DmPermitsCrudRoute = getCrudRoute({
  name: "DmPermits",
  icon: <CheckSquare />,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
});

const DmWellsCrudRoute = getCrudRoute({
  name: "DmWells",
  icon: <Droplet />,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
});

const DmAggregateSystemsCrudRoute = getCrudRoute({
  name: "DmAggregateSystems",
  icon: <CallMerge />,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
});

const dataEntryRoutes = {
  header: "Data Management",
  id: "Data Entry",
  icon: <Edit />,
  children: [
    {
      path: "/data-management/data-entry/well-production-data-entry",
      name: "Production",
      component: Production,
      guard: AuthGuard,
    },
    {
      path: "/data-management/data-entry/well-water-quality-data-entry",
      name: "Water Quality",
      component: NewWaterQuality,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/data-entry/well-water-level-data-entry",
      name: "Water Level",
      component: NewWaterLevels,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AuthGuard,
  visibilityFilter: UserVisibilityFilter,
};

const PermitsRoutes = {
  id: "Permits",
  icon: <Clipboard />,
  children: [
    getSidebarMenuItem({
      layoutOverride: "max",
      name: "DmPermits",
      sidebarName: "Active Permits",
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    }),
    {
      path: "/data-management/permits/historical-permits",
      name: "Historical Permits",
      component: UiReportAllPermitsReport,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/permits/permits-to-wells",
      name: "Permits to Wells",
      component: PermitsToWells,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/permits/logins-to-permits",
      name: "Logins to Permits",
      component: LoginsToPermits,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/permits/next-year-setup",
      name: "Next Year Setup",
      component: NextYearSetup,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const WellsRoutes = {
  id: "Wells",
  icon: <Droplet />,
  children: [
    getSidebarMenuItem({
      layoutOverride: "max",
      name: "DmWells",
      sidebarName: "Wells Management",
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    }),
    {
      path: "/data-management/wells/wells-search",
      name: "Wells Search",
      component: AllWellsReport,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/wells/well-owners-notice",
      name: "Wells/Owners Notice",
      component: WellOwnerSearchReport,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/wells/wells-to-owners",
      name: "Wells to Owners",
      component: WellsToOwners,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/wells/permits-to-wells",
      name: "Permits to Wells",
      component: PermitsToWells,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/wells/wells-to-contacts",
      name: "Wells to Contacts",
      component: WellsToContacts,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const DmAggregateSystemsMenuItem = getSidebarMenuItem({
  name: "DmAggregateSystems",
  sidebarName: "Aggregated Systems",
  icon: <CallMerge />,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
});

const OwnersRoutes = {
  id: "Owners",
  icon: <User />,
  children: [
    {
      path: "/data-management/owners/contacts-management",
      name: "Contacts Management",
      component: Rolodex,
      guard: AdminGuard,
    },
    {
      path: "/data-management/owners/well-owners-notice",
      name: "Wells/Owners Notice",
      component: WellOwnerSearchReport,
      guard: AdminGuard,
    },
    {
      path: "/data-management/owners/wells-to-owners",
      name: "Wells to Owners",
      component: WellsToOwners,
      guard: AdminGuard,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const reportsRoutes = {
  header: "Data Access",
  id: "Reports",
  icon: <FileText />,
  children: [
    {
      path: "/data-access/reports/water-levels",
      name: "Water Levels",
      component: WaterLevelsReport,
      guard: AdminGuard,
    },
    {
      path: "/data-access/reports/current-exempt-well-use",
      name: "Current Exempt Well Use",
      component: CurrentExemptWellUseSummaryReport,
      guard: AdminGuard,
    },
    {
      path: "/data-access/reports/water-quality-pdf",
      name: "Water Quality PDF",
      component: WaterQualityPDFReport,
      guard: AdminGuard,
    },
    {
      path: "/data-access/reports/pdi",
      name: "PDI",
      component: PDI,
      guard: AdminGuard,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const signalFireTelemetrySidebar = {
  id: "SignalFire Telemetry",
  path: "/data-access/signal-fire-telemetry",
  name: "SignalFire Telemetry",
  icon: <TrackChanges />,
  component: SignalFireTelemetry,
  visibilityFilter: AdminVisibilityFilter,
};

const signalFireTelemetryParamsRoute = {
  id: "SignalFire Telemetry",
  path: "/data-access/signal-fire-telemetry/:id?",
  name: "SignalFire Telemetry",
  component: SignalFireTelemetry,
  guard: AdminGuard,
};

const toolsRoutes = {
  id: "Tools",
  icon: <Tool />,
  children: [
    {
      path: "/data-access/tools/drawdown-tool",
      name: "Drawdown Tool",
      component: DrawdownTool,
      guard: AdminGuard,
    },
    {
      path: "/data-access/tools/water-quality-records-search",
      name: "Water Quality Records Search",
      component: WaterQualityRecordsSearch,
      guard: AdminGuard,
    },
    {
      path: "/data-access/tools/analytical-tool",
      name: "Analytical Tool",
      component: Blank,
      guard: AdminGuard,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const waterQualityRecordsSearchResultsRoute = {
  id: "Water Quality Records Search Results",
  path: "/data-access/tools/water-quality-records-search-results",
  name: "Water Quality Records Search Results",
  component: WaterQualityRecordsSearchResults,
  guard: AdminGuard,
};

const publicMapRoutes = {
  header: "Public Resources",
  id: "Interactive Map",
  icon: <Map />,
  path: ROUTES.PUBLIC_MAP,
  name: "Interactive Map",
  component: PublicMap,
};

const usersManagementRoutes = {
  header: "Admin",
  id: "Users Management",
  icon: <Users />,
  path: "/admin/users-management",
  name: "Users Management",
  component: UsersHome,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const userGuidesRoutes = {
  id: "User Guides",
  header: "Documents",
  icon: <HelpCircle />,
  path: "/data-access/documents/user-guides",
  name: "User Guides",
  component: UserGuides,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const publicFilesRoutes = {
  id: "Public Files",
  icon: <Archive />,
  path: "/data-access/documents/public-files",
  name: "Public Files",
  component: Blank,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

const clientDocsRoutes = {
  id: "Client Docs",
  icon: <Folder />,
  path: "/data-access/documents/client-docs",
  name: "Client Documents",
  component: Blank,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

const DeveloperDocsRoutes = {
  id: "Developer Docs",
  icon: <Briefcase />,
  path: "/data-access/documents/developer-docs",
  name: "Developer Documents",
  component: Blank,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

const developerTestRoutes = {
  header: "Developer",
  id: "Download Test",
  icon: <Code />,
  path: "/developer/test",
  name: "Download Test",
  component: DownloadTest,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
  visibilityFilter: UserVisibilityFilter,
};

export const dashboardLayoutRoutes = [
  mainRoutes,
  dataEntryRoutes,
  reportsRoutes,
  toolsRoutes,
  usersManagementRoutes,
  publicFilesRoutes,
  clientDocsRoutes,
  DeveloperDocsRoutes,
  userGuidesRoutes,
  accountRoutes,
  PermitsRoutes,
  WellsRoutes,
  OwnersRoutes,
  signalFireTelemetryParamsRoute,
  waterQualityRecordsSearchResultsRoute,
  developerTestRoutes,
];

export const dashboardMaxContentLayoutRoutes = [
  DmPermitsCrudRoute,
  DmWellsCrudRoute,
  DmAggregateSystemsCrudRoute,
  publicMapRoutes,
];

export const authLayoutRoutes = [];

export const presentationLayoutRoutes = [landingRoutes];

export const fullscreenMapRoutes = [];

export const sidebarRoutes = [
  mainRoutes,
  dataEntryRoutes,
  PermitsRoutes,
  WellsRoutes,
  DmAggregateSystemsMenuItem,
  OwnersRoutes,
  reportsRoutes,
  toolsRoutes,
  signalFireTelemetrySidebar,
  userGuidesRoutes,
  publicFilesRoutes,
  clientDocsRoutes,
  DeveloperDocsRoutes,
  publicMapRoutes,
  usersManagementRoutes,
  developerTestRoutes,
];
