import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { useApp } from "../../../AppProvider";

const MIN_YEAR = 1900;
const MAX_YEAR = 2100;

const validateYear = (value) => {
  if (
    !value ||
    !/^\d{4}$/.test(value) ||
    value < MIN_YEAR ||
    value > MAX_YEAR
  ) {
    return "Please enter a valid four-digit year (e.g., 2024).";
  }
  return "";
};

export const useNextYearSetup = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const [year, setYear] = useState(new Date().getFullYear());
  const [validationError, setValidationError] = useState("");

  const handleChangeYear = (event) => {
    const value = event.target.value;
    setYear(value);
    setValidationError(validateYear(value));
  };

  const mutation = useMutation(
    async ({ newYear }) => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/api/renew-all-permits`,
        { newYear },
        { headers }
      );
    },
    {
      onSuccess: () => {
        doToast(
          "success",
          "Permits renewed successfully. Redirecting... Happy New Year!"
        );
        history.push("/models/dm-permits");
      },
      onError: (error) => {
        const message =
          error?.response?.data?.message ||
          "Failed to renew permits. Do not try again. Please contact LRE Water for assistance.";
        doToast("error", message);
      },
    }
  );

  return {
    year,
    isValid: !validationError,
    isSubmitting: mutation.isLoading,
    validationError,
    isMutationError: mutation.isError,
    handleChangeYear,
    submitNewYear: () => mutation.mutate({ newYear: +year }),
  };
};

export default useNextYearSetup;
