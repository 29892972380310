import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { spacing } from "@material-ui/system";
import { Send as SendIcon } from "@material-ui/icons";

import useNextYearSetup from "./useNextYearSetup";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const StyledPaper = styled(Paper)`
  padding: 24px;
  margin: 24px auto;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const StyledButton = styled(Button)`
  height: 56px;
  width: 180px;
  font-size: 1rem;
  margin-top: 16px;
`;

export default function NextYearSetup() {
  const {
    year,
    isValid,
    isSubmitting,
    validationError,
    isMutationError,
    handleChangeYear,
    submitNewYear,
  } = useNextYearSetup();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpenConfirm = () => setConfirmOpen(true);
  const handleCloseConfirm = () => setConfirmOpen(false);

  return (
    <>
      <Helmet title="Next Year Setup" />

      <Typography variant="h3" gutterBottom display="inline">
        Next Year Setup
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/dashboard">
          Dashboard
        </Link>
        <Typography>Next Year Setup</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Alert severity="info">
            This process will renew all permits for the specified year. It may
            take up to 30 seconds. Please do not navigate away from this page
            until the process finishes. You will be redirected upon success.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>
              Renew Permits for Next Year
            </Typography>
            <TextField
              label="Year"
              variant="outlined"
              type="number"
              value={year}
              onChange={handleChangeYear}
              disabled={isSubmitting || isMutationError}
              error={!!validationError}
              helperText={validationError || ""}
              fullWidth
              margin="normal"
            />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleOpenConfirm}
              disabled={isSubmitting || !isValid || !!isMutationError}
              startIcon={isSubmitting ? null : <SendIcon />}
            >
              {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
            </StyledButton>
          </StyledPaper>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={confirmOpen}
        title="Confirm Next Year Setup"
        description={`Are you sure you want to renew all permits for the year: ${year}? This process cannot be undone once started.`}
        onCancel={handleCloseConfirm}
        onConfirm={submitNewYear}
        isLoading={isSubmitting}
        isMutationError={isMutationError}
      />
    </>
  );
}

const ConfirmationDialog = ({
  open,
  title,
  description,
  onCancel,
  onConfirm,
  isLoading,
  isMutationError,
}) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary" disabled={isLoading}>
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        color="secondary"
        variant="contained"
        autoFocus
        disabled={isLoading || isMutationError}
        startIcon={isLoading && <CircularProgress size={20} />}
      >
        {isLoading ? "Processing..." : "Confirm"}
      </Button>
    </DialogActions>
  </Dialog>
);
